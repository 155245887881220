@import './breakpoints.scss';

#carousel {
  margin-top: 60px;
  position: relative;
  background: var(--color-bg);
  height: calc(100vh - 60px);
  overflow-x: hidden;

  .inner {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
  }

  .arrow {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 0.5;
    }

    &.left {
      left: 40px;
    }

    &.right {
      right: 40px;

      img {
        transform: rotate(180deg);
      }
    }
  }

  .dots {
    position: absolute;
    bottom: 20px;
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    z-index: 1;

    .dot {
      width: 10px;
      height: 10px;
      background: var(--color-white);
      opacity: 0.4;
      border-radius: 10px;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.active {
        opacity: 1;
      }
    }
  }

  .block {
    background-size: cover;
    background-position: center;
    flex: 1;
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
    box-shadow: var(--box-shadow-carousel);

    .block-inner {
      display: flex;
      flex-direction: column;
      max-width: 478px;
      margin-left: 102px;
      margin-top: 18%;
      color: #060315;

      h1 {
        font-size: 43px;
        line-height: 47px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 20px;
      }

      h2 {
        font-size: 24px;
        line-height: 31px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 30px;
      }

      .button {
        padding: 10px 50px;
        border-radius: 25px;
        font-weight: 700;
        line-height: 30px;
        font-size: 20px;
      }

      @media (max-width: 320px) {
        h1 {
          font-size: 24px !important;
          line-height: 31px !important;
        }

        h2 {
          font-size: 16px !important;
          line-height: 20px !important;
        }
      }
    }

    &.block-job-hunt {
      background-image: url(../assets/img/carousel-1.png);
      background-color: #fbb4c4;

      @media (max-width: $mediaSm) {
        background-image: url(../assets/img/carousel-1-mobile.png);
      }
    }

    &.block-middleman {
      background-image: url(../assets/img/carousel-2.png);
      background-color: #f9fafe;

      @media (max-width: $mediaSm) {
        background-image: url(../assets/img/carousel-2-mobile.png);
      }
    }

    &.block-difference {
      background-image: url(../assets/img/carousel-3.png);
      background-color: #f9fafe;

      @media (max-width: $mediaSm) {
        background-image: url(../assets/img/carousel-3-mobile.png);
      }
    }

    &.block-no-bias {
      background-image: url(../assets/img/carousel-4.png);
      background-color: #ffdd14;

      @media (max-width: $mediaSm) {
        background-image: url(../assets/img/carousel-4-mobile.png);
      }
    }
  }

  .pick-a-role {
    position: absolute;
    bottom: 0;
    left: 40px;
    padding: 12px 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: var(--color-bg);
    display: block;
    color: var(--color-text);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-decoration: none;
  }

  @media (max-width: $mediaSm) {
    margin-top: 43px;
    height: calc(100vh - 43px);

    .arrow {
      display: none;
    }

    .dots {
      right: 20px;

      left: unset;
      transform: unset;
    }

    .block {
      .block-inner {
        margin: 50px 75px 0 20px;

        h1 {
          font-size: 34px;
          line-height: 35px;
          margin-bottom: 10px;
        }

        h2 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      }
    }

    .pick-a-role {
      left: 20px;
      padding: 12px 19px;
    }
  }
}
