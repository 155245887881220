.footer {
  width: 90%;
  margin: 0 auto;
  text-align: center;

  .copy {
    color: #7b7b7b;
    font-size: 12px;
    margin: 15px 0px;
    font-weight: 500;
  }

  .links > .link {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}
