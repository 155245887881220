@import './breakpoints.scss';

.chat {
  padding: 64px 84px 100px 84px;
  margin-top: -20px;
  max-width: 100%;
  z-index: 1;
  background-color: var(--color-bg);

  .messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }

  .chat-message {
    background-color: var(--color-message-bg);
    border-radius: 54px;
    border-bottom-right-radius: 4px;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    width: fit-content;
    align-self: flex-end;
    padding: 32px;

    .highlighted {
      color: var(--color-message-highlighted);
    }

    &.incoming {
      border: 1px solid #7fece7;
      border-bottom-right-radius: 54px;
      border-bottom-left-radius: 4px;
      align-self: flex-start;
      background-color: var(--color-message-inc-bg);
    }
  }

  .button {
    align-self: flex-end;
    padding: 16px 32px;
    border-radius: 32px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    margin-top: 2px;
    margin-bottom: 32px;
  }

  @media (max-width: $mediaSm) {
    padding: 50px 20px 60px 20px;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
  }
}
