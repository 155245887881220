@import './breakpoints.scss';

.cookie-consent {
  width: 100%;
  background-color: #8654f3;
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: none;

  .close-icon {
    width: 17px;
    height: 90px;
    background: url(../assets/svg/close.svg) no-repeat;
    float: right;
    margin-left: 90px;
    margin-top: 9px;
  }

  .inner {
    width: 100%;
    max-width: 1440px;
    min-height: 100px;
    margin: 20px auto;
    background: url(../assets/img/cookies.png) no-repeat left top;
    padding-left: 160px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    color: var(--color-text-inverted);
  }

  .link {
    font-weight: 700;
    color: var(--color-text-inverted);
  }

  @media (max-width: $mediaSm) {
    .close-icon {
      margin-left: 20px;
    }

    .inner {
      max-width: 100%;
      padding: 0 20px !important;
      background: none;
    }

    .link,
    .inner {
      font-size: 12px;
      line-height: 20px;
    }
  }

  @media (max-width: $mediaMd) {
    .inner {
      padding-left: 100px;
    }
  }

  @media (max-width: $mediaXxl) {
    .inner {
      padding-right: 50px;
    }
  }
}
