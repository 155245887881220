body.mobile-menu-active .mobile-menu {
  display: flex;
}

.mobile-menu {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  z-index: 4;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 85%;
  background-color: #cb2f5f;
  opacity: 0.98;
  font-weight: 500;
  padding-top: 66px;
  padding-left: 20px;

  a,
  a:visited {
    display: block;
    color: var(--color-white);
    font-size: 18px;
    width: 80%;
    border-bottom: 1px solid #dd7897;
    height: 45px;
    line-height: 45px;
    text-decoration: none;
  }
}
