@import './breakpoints.scss';

#burger-button {
  width: 25px;
  height: 17px;
  border: 0;
  z-index: 5;
  position: fixed;
  top: 14px;
  right: 16px;
  display: none;

  .line {
    display: block;
    background: var(--color-text);
    width: 25px;
    height: 3px;
    position: absolute;
    left: 0;
    transition: all 0.6s;
    border: 0px;
    border-radius: 1.5px;

    &.line-1 {
      top: 0px;
    }

    &.line-2 {
      top: 7px;
    }

    &.line-3 {
      top: 14px;
    }
  }

  @media (max-width: $mediaSm) {
    display: block;
  }
}

body.mobile-menu-active #burger-button .line-1 {
  transform: translateY(7px) translateX(0) rotate(45deg);
}

body.mobile-menu-active #burger-button .line-2 {
  opacity: 0;
}

body.mobile-menu-active #burger-button .line-3 {
  transform: translateY(-7px) translateX(0) rotate(-45deg);
}
