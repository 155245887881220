@import './breakpoints.scss';

#job-posts {
  display: none;
  justify-content: space-between;
  padding: 75px 40px 78px 40px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  &.initialized {
    display: flex;
  }

  .categories {
    display: flex;
    flex-direction: column;
    flex-basis: 225px;
    position: sticky;
    top: 80px;
    height: fit-content;

    h2 {
      margin-top: 0;
      line-height: 32px;
      margin-bottom: 32px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
      margin-bottom: 16px;
      font-weight: 400;
    }

    .categories-list {
      display: flex;
      flex-wrap: wrap;

      .category-tag {
        cursor: pointer;
        border: 1px solid #d5ddf1;
        border-radius: 20px;
        padding: 4px 16px;
        background: transparent;
        color: var(--color-text);
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 8px;
        transition: all 200ms;

        &:not(:last-child) {
          margin-right: 8px;
        }

        &.active,
        &:hover {
          border: 1px solid #009e96;
        }

        &.active {
          color: var(--color-text-inverted);
          background: #009e96;
        }
      }
    }
  }

  .job-posts-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex: 1;

    .job-post {
      display: flex;
      flex-direction: column;
      background: var(--color-card-bg);
      border-radius: 28px;
      padding: 40px 32px;
      flex-basis: 440px;
      margin-right: 24px;
      margin-bottom: 24px;
      box-shadow: var(--box-shadow-card);

      &.spacer {
        opacity: 0;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 4px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: var(--color-title);
      }

      .header {
        display: flex;
        margin-bottom: 12px;

        .user-avatar {
          margin-right: 12px;
          width: 64px;
          height: 64px;
          min-width: 64px;
          min-height: 64px;
          background-size: contain;
        }

        .title {
          display: flex;
          flex-direction: column;
          word-break: break-all;

          h2 {
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            color: var(--color-text-secondary);
            font-weight: 400;
          }
        }
      }

      .icon-tags {
        display: flex;
        flex-wrap: wrap;
        min-height: 24px;

        .icon-tag {
          display: flex;
          align-items: center;

          &:first-child {
            margin-right: 13px;
          }

          .icon {
            width: 16px;
            height: 16px;
            margin-right: 8px;

            &.job {
              background: url(../assets/svg/job-icon.svg);
            }

            &.location {
              background: url(../assets/svg/location-icon.svg);
            }
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
            color: var(--color-text-secondary);
            text-transform: capitalize;
          }
        }
      }

      .looking-for {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--color-looking-for);
        margin-top: 32px;
        margin-bottom: 8px;
      }

      .description {
        margin-top: 4px;
        margin-bottom: 16px;
        overflow: hidden;

        &, p, span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: var(--color-description);
        }

        p {
          margin: 0;
        }
      }

      .tags {
        display: flex;
        flex-wrap: wrap;

        .tag {
          background-color: var(--color-tag-bg);
          border: 1px solid var(--color-tag-border);
          color: var(--color-tag-text);
          border-radius: 15px;
          padding: 4px 16px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }

      .button {
        margin-top: auto;
        padding: 8px 20px;
        background-color: var(--color-button-secondary-bg);
        margin-top: 64px;
        color: var(--color-text);

        &:hover {
          background-color: var(--color-button-secondary-bg-hover);
        }
      }
    }
  }

  @media (max-width: $mediaMd) {
    flex-direction: column;
    padding: 64px 20px;

    .categories {
      flex-basis: 100%;
      position: relative;
      top: 0;

      .categories-list {
        margin-bottom: 16px;
      }
    }

    .job-posts-list {
      flex-direction: column;

      .job-post {
        flex-basis: unset;
        margin-right: 0;
        padding: 40px 20px;

        .icon-tags {
          .icon-tag {
            align-items: baseline;
          }
        }

        .tags {
          .tag {
            margin-bottom: 8px;
          }
        }

        .looking-for {
          margin-top: 38px;
        }
      }
    }
  }
}
