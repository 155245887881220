@import './breakpoints.scss';

.features {
  display: flex;
  flex-direction: column;
  padding: 50px 40px 80px 40px;
  background: #26253f;
  color: var(--color-white);

  .inner {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 175px;

    h1 {
      line-height: 48px;
      margin: 0;
      margin-right: 200px;
    }

    p {
      max-width: 500px;
      margin: 0;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
    }
  }

  .cards {
    display: flex;

    .card {
      flex: 1;
      background: #363656;
      border-radius: 8px;
      padding: 0 32px 42px 32px;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &.card-1 > .img {
        background-image: url(../assets/img/unbiased.png);
      }

      &.card-2 > .img {
        background-image: url(../assets/img/anonymous.png);
      }

      &.card-3 > .img {
        background-image: url(../assets/img/noise-free.png);
      }

      .img {
        margin-left: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 130px;
        height: 130px;
        margin-top: -30px;
      }

      h2 {
        font-size: 27px;
        line-height: 32px;
        margin-top: 20px;
        margin-bottom: 8px;
      }

      p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        margin-top: 0;
      }
    }
  }

  @media (max-width: $mediaMd) {
    padding: 50px 20px 70px 20px;

    &.rounded {
      border-top-right-radius: 28px;
      border-top-left-radius: 28px;
    }

    .header {
      flex-direction: column;
      margin-bottom: 73px;

      h1 {
        margin-right: 0;
        margin-bottom: 20px;
      }

      p {
        max-width: 500px;
        margin: 0;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
      }
    }

    .cards {
      flex-direction: column;

      .card {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 60px;
        }
      }
    }
  }
}
