@import './breakpoints.scss';

@font-face {
  font-family: 'Helvetica Now';
  font-weight: 400;
  src: url('../assets/fonts/5760841/46dd84ea-bb96-46da-973b-d7fcca46437e.woff2')
      format('woff2'),
    url('../assets/fonts/5760841/0fb90058-c064-40ed-8109-d1fd8633caa2.woff')
      format('woff');
}

@font-face {
  font-family: 'Helvetica Now';
  font-weight: 500;
  src: url('../assets/fonts/5761058/e62ce8f4-9983-4262-9030-3fdbebcebf39.woff2')
      format('woff2'),
    url('../assets/fonts/5761058/0e8ae071-ceee-4b41-9d37-cbb6af5a9ca9.woff')
      format('woff');
}

@font-face {
  font-family: 'Helvetica Now';
  font-weight: 700;
  src: url('../assets/fonts/5760915/f0cbab32-010c-4ff1-8be8-7d74011f2548.woff2')
      format('woff2'),
    url('../assets/fonts/5760915/79e75c13-a7cb-4154-83dc-4dfb2aec1b11.woff')
      format('woff');
}

body {
  background-color: var(--color-bg);
  font-family: 'Helvetica Now', sans-serif;
  color: var(--color-text);
  margin: 0;
  width: 100%;
  height: 100%;

  &.mobile-menu-active {
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
}

.hidden {
  display: none !important;
}

.link {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-text);
  text-decoration: none;

  &:visited {
    color: var(--color-text);
  }

  &:hover {
    color: #59e2be;
  }
}

.button {
  display: block;
  text-decoration: none;
  outline: none;
  border: none;
  background: var(--color-accent);
  border-radius: 20px;
  padding: 4px 18px;
  color: var(--color-white);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  width: fit-content;
  transition: 200ms all;

  &:hover {
    background: var(--color-button-hover);
  }

  &.big {
    padding: 10px 50px;
    border-radius: 25px;
    font-weight: 700;
    line-height: 30px;
    font-size: 20px;
  }
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: var(--color-bg);
  z-index: 3;
  padding: 19px 40px;
  background: var(--color-bg);
  box-shadow: var(--box-shadow-card);

  .logo {
    width: 96px;
    min-width: 96px;
    height: 19px;
    background: url('../assets/svg/logo-dark.svg');
  }

  .navigation {
    display: flex;
    align-items: center;

    .sign-up-links {
      display: flex;
      align-items: center;
      margin-left: 127px;
    }

    .open-app-button {
      margin-left: 196px;
    }

    .link {
      margin-right: 19px;
      white-space: nowrap;
    }

    .burger-placeholder {
      width: 25px;
      height: 17px;
      margin-left: 20px;
      display: none;
    }
  }

  @media (max-width: $mediaSm) {
    padding: 12px 16px;

    .navigation {
      .link {
        display: none;
      }

      .open-app-button,
      .sign-up-links {
        margin-left: 0;
      }

      .burger-placeholder {
        display: block;
      }
    }

    .button {
      padding: 8px 20px;
    }

    .sign-up-links {
      display: none;
    }
  }

  @media (max-width: $mediaMd) {
    .navigation {
      .sign-up-links,
      .open-app-button {
        margin-left: 25px;
      }
    }
  }
}

#button-up {
  align-self: flex-end;
  display: block;
  width: 48px;
  height: 48px;
  background-image: url(../assets/svg/up-arrow.svg);
  background-repeat: no-repeat;
  border-radius: 100px;
  background-position: center;
  background-color: var(--color-accent);
  transform: rotate(90deg);
  margin-left: auto;
  margin: 14px 14px 14px auto;
  position: sticky;
  top: calc(100vh - 80px);
  transition: 200ms background-color;

  &:hover {
    background-color: var(--color-button-hover);
  }
}
