body {
  --color-white: #fff;
  --color-bg: #f9fafe;
  --color-accent: #f30e66;
  --color-header-bg: var(--color-white);
  --color-text: #000626;
  --color-text-inverted: var(--color-white);
  --color-card-bg: var(--color-white);
  --box-shadow-card: 0 5px 20px 0 rgba(0, 129, 204, 0.1);
  --box-shadow-carousel: inset 0px -100px 100px -100px rgba(0, 102, 204, 0.22);
  --color-text-secondary: #6d748d;
  --color-title: var(--color-text);
  --color-looking-for: var(--color-text-secondary);
  --color-description: var(--color-looking-for);
  --color-tag-bg: #f2f7fc;
  --color-tag-border: var(--color-tag-bg);
  --color-tag-text: #414660;
  --color-button-secondary-bg: var(--color-tag-bg);
  --color-button-secondary-bg-hover: #edf2f9;
  --color-message-highlighted: var(--color-accent);
  --color-message-bg: #7fece7;
  --color-message-inc-bg: var(--color-white);
  --color-button-hover: #e70965;
}
